﻿// Function to wrap words surrounded by "*" in a <span> with a dynamically set class
function emphasizeHeadlines() {
    // Retrieve the value of the CSS custom property --emphasize-headline
    const emphasizeClass = getComputedStyle(document.documentElement).getPropertyValue('--emphasize-headline').trim();

    // Select all headings (h1 through h5)
    const headings = document.querySelectorAll('h1, h2, h3, h4, h5');

    headings.forEach(heading => {
        // Replace "br/" with <br> tags
        heading.innerHTML = heading.innerHTML.replace(/br\//g, '<br>');

        // Find words wrapped in "*" and replace with a span containing the retrieved class
        heading.innerHTML = heading.innerHTML.replace(/\*(.*?)\*/g, (match, p1) => {
            return `<span class="${emphasizeClass}">${p1}</span>`;
        });
    });
}

// Call the function after DOM is loaded
document.addEventListener('DOMContentLoaded', emphasizeHeadlines);

﻿import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

document.addEventListener("DOMContentLoaded", function () {
    const netflixSlider = document.querySelector(".netflix-slider");
    const netflixSlideCount = netflixSlider ? netflixSlider.querySelectorAll('.swiper-slide').length : 0;

    const netflixSwiper = new Swiper(".netflix-slider", {
        slidesPerView: 1.10,
        watchSlidesProgress: true,
        spaceBetween: 16,
        breakpoints: {
            640: { spaceBetween: 24, slidesPerView: 2 },
            992: { spaceBetween: 24, slidesPerView: 2 },
            1280: { spaceBetween: 24, slidesPerView: 3 },
        },
        navigation: {
            nextEl: '.ph-caret-right',
            prevEl: '.ph-caret-left',
        },
    });
});

document.addEventListener("DOMContentLoaded", function () {
    const slideshow = document.querySelector(".slideshow");
    const slideshowSlideCount = slideshow ? slideshow.querySelectorAll('.swiper-slide').length : 0;

    const slideshowSwiper = new Swiper(".slideshow", {
        slidesPerView: 1,
        loop: slideshowSlideCount > 1, // Enable loop only if there are enough slides
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
        },
        on: {
            init: function () {
                updateSlideCounter(this);
            },
            slideChange: function () {
                updateSlideCounter(this);
            }
        }
    });

    function updateSlideCounter(swiper) {
        const currentIndex = swiper.realIndex + 1;
        const counter = document.querySelector('.swiper-counter');
        if (counter) {
            counter.textContent = currentIndex;
        }
    }
});

document.addEventListener("DOMContentLoaded", function () {
    const autoSlider = document.querySelector(".auto");
    const autoSlideCount = autoSlider ? autoSlider.querySelectorAll('.swiper-slide').length : 0;

    const autoSwiper = new Swiper(".auto", {
        slidesPerView: 1,
        loop: autoSlideCount > 1, // Enable loop only if there are enough slides
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        }
    });
});

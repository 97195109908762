import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Fade effects
(function () {
    const animatedElements = gsap.utils.toArray('[data-animate]');

    animatedElements.forEach((el) => {
        const animation = el.getAttribute('data-animate');
        const delay = parseInt(el.getAttribute('data-delay')) || 0;

        if (animation) {
            gsap.from(el, {
                opacity: 0,
                ...getAnimationProperties(animation),
                duration: 0.2,
                delay: delay / 1000, // Convert delay to seconds
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom",
                    toggleActions: "play none none none",
                },
            });
        }
    });

    function getAnimationProperties(animation) {
        const animations = {
            "fade-up": { y: 20 },      
            "fade-down": { y: -20 },   
            "fade-right": { x: -20 },  
            "fade-left": { x: 20 },    
            "fade": {},
        };

        return animations[animation] || {};
    }
})();

// Parallax effect
(function () {
    if (document.querySelector(".parallax")) {  // Check for .parallax elements
        gsap.fromTo(".parallax",
            { yPercent: -20 },
            {
                yPercent: 20,
                ease: "none",
                scrollTrigger: {
                    trigger: ".parallax",
                    start: "top bottom",
                    end: "bottom top",
                    scrub: true,
                },
            }
        );
    }
})();

// Scroll spy
(function () {
    if (document.querySelector("#scroll-spy")) {  // Check for #scroll-spy
        gsap.to("#scroll-spy", {
            width: "100%",
            ease: "none",
            scrollTrigger: {
                trigger: "body",
                start: "top top",
                end: "bottom bottom",
                scrub: true,
            },
        });
    }
})();


export function animatedCounter(target, time = 2000, start = 0) {
    return {
        current: start,
        target: 0,
        time: time,
        animation: null,
        init() {
            if (typeof (target) === "string") {
                this.target = this.$data[target]
            } else {
                this.target = target
            }

            const element = this.$el;

            ScrollTrigger.create({
                trigger: element,
                start: "top bottom",
                onEnter: () => this.updateCounter(),
                onEnterBack: () => this.updateCounter(),
                once: true,
            });

            if (ScrollTrigger.isInViewport(element)) {
                this.updateCounter();
            }
        },
        updateCounter() {
            if (typeof (target) === "string") {
                this.target = this.$data[target]
            }

            const element = this.$el;
            const counterSpan = element.querySelector('.counter-span');
            const progressBar = element.closest('.number')?.querySelector('.progress-bar');  // Check for .progress-bar within this instance

            gsap.to(counterSpan, { opacity: 1 });

            if (this.animation) {
                this.animation.kill();
            }

            // Set the starting point for countdown
            const duration = this.time / 1000;

            this.animation = gsap.to(this, {
                current: this.target,
                duration: duration,
                onUpdate: () => {
                    if (counterSpan) {
                        counterSpan.innerText = this.current.toFixed(2).toLocaleString('da-DK');
                    }
                },
                onComplete: () => {
                    if (counterSpan) {
                        counterSpan.innerText = this.target.toFixed(2).toLocaleString('da-DK');
                    }
                }
            });

            // Only animate .progress-bar if it exists in the DOM for this instance
            if (progressBar) {
                gsap.to(progressBar, {
                    width: "100%", // Animate to 100% width
                    duration: this.time / 1000,
                    ease: "power1.out",
                    overwrite: true
                });
            }
        }
    };
}